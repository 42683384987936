import React from "react"
import Container from "../components/container"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"


export default () => (
    <Container>
        <Helmet>
          <title>Cast &amp; Crew | An Exquisite Meal</title>
          <meta name="description" content="Cast, crew, and more information." />
        </Helmet>
        
        <div class="sub-container-page">
            <div class="body-content">
                <a href="/"><img src="../AnExquisiteMeal_headline.png" width="600" alt="An Exquisite Meal" /></a>
                <h2 class="headline">Credits.</h2>
                
                <p class="description-credits">

                Written and directed by <a href="https://robertbrucecarter.com" target="_new">Robert Bruce Carter</a><br />
                    Produced by <a href="https://www.joshitzkowitz.com/" target="_new">Josh Itzkowitz</a><br />
                    Co-producer // <a href="http://www.unfurnishedfilms.com/" target="_new">Curtis Matzke</a><br />
                </p>


                <h3>Cast</h3>
                <p class="description-credits">
                    Mike Jimerson<br />
                    Amrita Dhaliwal<br />
                    Victoria Nugent<br />
                    Ross Magyar<br />
                    Mark Pracht<br />
                    Emily Marso<br />
                    Bassam Abdelfattah<br />
                    Siddhartha Rajan<br />
                    Luke Johnson
                </p>


                <h3>Crew</h3>
                <p class="description-credits">
                    Cinematographer // <a href="https://www.zoelubeckfilms.com/" target="_new">Zoe Lubeck</a><br />
                    Production Designer // Allison Kilberg<br />
                    Editor // Anna Pinchuk<br />
                    Associate Producer // Joshua Wilkerson<br />
                    Composer // Jessica Jarvis<br />
                    Casting Director // Sarah Clark, CSA<br />
                    Casting Assistant // Jill Allenby<br />
                    Production Sound // Alex Phillips<br />
                    Key Grip // Agron Karameti<br />
                    Additional Camera Operator // Curtis Matzke<br />
                    Makeup // Jacqueline Meller-Vogt<br />
                    Original Artwork // Paul Kenneth, Lara Mann<br />
                    Still Photography // Brian McConkey, Jeanne Donegan
                </p>

                <h3>Post-production</h3>
                <p class="description-credits">
                    Colorist // Matthew Filipek<br/>
                    Sound Design // Brian Flood<br/>
                    Visual Effects // Kris Sundberg<br/>
                    Title Designer // Sara Jean Potts<br/>
                    ADR Engineers // Ralph Loza (Experimental Sound Studio), Ted Wulfers
                </p>
                
                <h3>Music</h3>
                <p class="description-credits">
                    “Build Me a House”<br />
                    Written &amp; Performed by <a href="https://www.facebook.com/sophiakennedyofficial/" target="new">Sophia Kennedy</a><br />
                    Courtesy of Pampa Records
                </p>
                <p class="description-credits">
                    “Luna”<br />
                    Performed by <a href="https://viviangarcia.bandcamp.com/" target="new">Vivian Garcia</a><br />
                    Written by Vivian Garcia &amp; Mike Przygoda
                </p>
                <p class="description-credits">
                    “Love and Kindness”<br />
                    Performed by <a href="http://www.indelicates.com/" target="new">The Indelicates</a><br />
                    Written by Simon &amp; Julia Indelicate<br />
                    Courtesy of Corporate Records
                </p>    
                <p class="description-credits">
                    “No King”<br />
                    Written &amp; Performed by Outcalls<br />
                    Courtesy of <a href="https://www.outcallsband.com/" target="new">Outcalls</a>
                </p>
                                    
                <h3>---</h3>
                <p class="description"><em>This project is partially supported by an Individual Artist Program Grant from the City of Chicago Department of Cultural Affairs &amp; Special Events.</em></p>
                

            
            </div>
        </div>
        <Footer>
        </Footer>
    </Container> 
    
)

